import { SiFacebook, SiInstagram, SiLinkedin, SiX } from "@icons-pack/react-simple-icons";
import { Link } from "@remix-run/react";
import { Mail } from "lucide-react";

export default function SocialLinks() {
    return (
        <>
            {/* <Link
                to="https://twitter.com/tailoredcvai"
                className="text-gray-500 hover:text-purple-600"
            >
                <SiX className="h-5 w-5" />
                <span className="sr-only">X (Twitter)</span>
            </Link> */}
            <Link
                to="https://www.linkedin.com/company/tailoredcv-ai/"
                className="text-gray-500 hover:text-purple-600"
            >
                <SiLinkedin className="h-5 w-5" />
                <span className="sr-only">LinkedIn</span>
            </Link>
            {/* <Link
                to="https://facebook.com/tailoredcvai"
                className="text-gray-500 hover:text-purple-600"
            >
                <SiFacebook className="h-5 w-5" />
                <span className="sr-only">Facebook</span>
            </Link> */}
            <Link
                to="https://www.instagram.com/tailoredcv.ai/"
                className="text-gray-500 hover:text-purple-600"
            >
                <SiInstagram className="h-5 w-5" />
                <span className="sr-only">Instagram</span>
            </Link>
            <Link to="mailto:support@tailoredcv.ai" className="text-gray-500 hover:text-purple-600">
                <Mail className="h-5 w-5" />
            </Link>
        </>
    );
}
